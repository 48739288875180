import { get, curry } from 'lodash';
import { WARRANTY_MAX_TYPE, WARRANTY_TYPE } from 'site-modules/shared/constants/inventory/warranty';
import { isCpo } from 'site-modules/shared/utils/inventory-utils/is-cpo';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { isCarMax, isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { getCurrentDate } from 'site-modules/shared/utils/date-utils';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { getPlural } from 'client/utils/plural';

const isElectricWarrantyType = warrantyType =>
  warrantyType === WARRANTY_TYPE.EV_BATTERY || warrantyType === WARRANTY_TYPE.HYBRID_COMPONENT;

export const sortWarranty = warranty =>
  [...warranty].sort((left, right) => {
    if (isElectricWarrantyType(left.warrantyType)) {
      return -1;
    }

    if (isElectricWarrantyType(right.warrantyType)) {
      return 1;
    }

    if (left.warrantyType === WARRANTY_TYPE.BASIC) {
      return -1;
    }

    if (right.warrantyType === WARRANTY_TYPE.BASIC) {
      return 1;
    }

    if (left.warrantyType === WARRANTY_TYPE.DRIVETRAIN) {
      return -1;
    }

    if (right.warrantyType === WARRANTY_TYPE.DRIVETRAIN) {
      return 1;
    }

    return 0;
  });

export const getWarrantyYearsLeft = (warrantyItem, vinData) => {
  const currentYear = get(vinData, DATA_PATH.YEAR, 0);
  const yearsOld = getCurrentDate().getFullYear() - currentYear;

  if (warrantyItem.maxYearsType === WARRANTY_MAX_TYPE.UNLIMITED) {
    return Number.MAX_SAFE_INTEGER;
  }

  return Math.max(Number(warrantyItem.maxYears) - yearsOld, 0);
};
export const getWarrantyMilesLeft = (warrantyItem, vinData) => {
  const currentMiles = get(vinData, DATA_PATH.MILEAGE, 0);

  if (warrantyItem.maxMileageType === WARRANTY_MAX_TYPE.UNLIMITED) {
    return Number.MAX_SAFE_INTEGER;
  }

  return Math.max(Number(warrantyItem.maxMileage) - currentMiles, 0);
};

export function isWarrantyYearsEligible(vinData, warrantyItem) {
  return !!getWarrantyYearsLeft(warrantyItem, vinData);
}

export function isWarrantyMileageEligible(vinData, warrantyItem) {
  return !!getWarrantyMilesLeft(warrantyItem, vinData);
}

export const isWarrantyItemEligible = curry(
  (vinData, warrantyItem) =>
    isWarrantyYearsEligible(vinData, warrantyItem) && isWarrantyMileageEligible(vinData, warrantyItem)
);

export function isUsedWarrantyApplicable(vinData) {
  const type = get(vinData, DATA_PATH.INVENTORY_TYPE, '');
  const dealershipName = get(vinData, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
  const salvageHistory = get(vinData, DATA_PATH.SALVAGE_HISTORY, false);
  const historyCleanTitle = get(vinData, DATA_PATH.HISTORY_CLEAN_TITLE, false);
  return !isCpo(type) && !isCarMax(dealershipName) && (!salvageHistory || historyCleanTitle);
}

export const isNewWarrantyApplicable = vinData => isPartnerDealer(vinData);

export function isWarrantyApplicable(vinData) {
  const type = get(vinData, DATA_PATH.INVENTORY_TYPE, '');
  return isNew(type) ? isNewWarrantyApplicable(vinData) : isUsedWarrantyApplicable(vinData);
}

export const isWarrantyDefined = (vinData, warranty) => {
  if (!(warranty && warranty.length)) {
    return false;
  }

  const type = get(vinData, DATA_PATH.INVENTORY_TYPE, '');

  return isNew(type) || warranty.some(isWarrantyItemEligible(vinData));
};

export const sortWarrantyByExpiration = (vinData, warranty) =>
  [...warranty].sort((left, right) => {
    if (isWarrantyItemEligible(vinData, left) && !isWarrantyItemEligible(vinData, right)) {
      return -1;
    }

    if (isWarrantyItemEligible(vinData, right) && !isWarrantyItemEligible(vinData, left)) {
      return 1;
    }

    return 0;
  });

export const warrantyConditionsUsedDetailedCopyProviders = {
  getYearsCopy(vinData, warrantyItem) {
    const yearsLeft = getWarrantyYearsLeft(warrantyItem, vinData);

    if (yearsLeft === Number.MAX_SAFE_INTEGER) {
      return 'Unlimited years';
    }

    return isWarrantyYearsEligible(vinData, warrantyItem)
      ? `${yearsLeft} ${getPlural('year', yearsLeft)} left`
      : 'Exceeded years';
  },
  getMilesCopy(vinData, warrantyItem) {
    const milesLeft = getWarrantyMilesLeft(warrantyItem, vinData);

    if (milesLeft === Number.MAX_SAFE_INTEGER) {
      return 'Unlimited miles';
    }

    return isWarrantyMileageEligible(vinData, warrantyItem)
      ? `${numberWithCommas(milesLeft)} ${getPlural('mile', milesLeft)} left`
      : 'Exceeded miles';
  },
};

export const warrantyConditionsUsedCopyProviders = {
  getYearsCopy(vinData, warrantyItem) {
    const yearsLeft = getWarrantyYearsLeft(warrantyItem, vinData);

    return yearsLeft === Number.MAX_SAFE_INTEGER ? 'unlimited yr' : `${yearsLeft} yr`;
  },
  getMilesCopy(vinData, warrantyItem) {
    const milesLeft = getWarrantyMilesLeft(warrantyItem, vinData);

    return milesLeft === Number.MAX_SAFE_INTEGER ? 'unlimited mi' : `${numberWithCommas(milesLeft)} mi`;
  },
  getConditionsCopy(vinData, warrantyItem) {
    if (!isWarrantyYearsEligible(vinData, warrantyItem)) {
      return 'Exceeded yr';
    }

    if (!isWarrantyMileageEligible(vinData, warrantyItem)) {
      return 'Exceeded mi';
    }

    return `${this.getYearsCopy(vinData, warrantyItem)} or ${this.getMilesCopy(vinData, warrantyItem)}`;
  },
};
