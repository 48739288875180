import { get, isEmpty } from 'lodash';
import { getRatingScale } from 'site-modules/shared/utils/editorial-helper';
import { SCHEMA_URL } from 'site-modules/shared/pages/seo';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { parseContent } from 'client/data/cms/content';
import { getValidImageUrl, SOURCES } from 'client/utils/image-helpers';
import { getOrganizationSchema } from './organization';
import { getPersonSchema } from './person';

export function getAggregateRatingFromCoreConsumerReviews(consumerReviews) {
  const consumerRatingValue =
    get(consumerReviews, 'ratingAggregation.averageStars') || get(consumerReviews, 'averageUserRating');
  const consumerReviewsCount =
    get(consumerReviews, 'totalReviews') || get(consumerReviews, 'totalNumber') || get(consumerReviews, 'totalCount');

  return consumerRatingValue
    ? {
        '@context': SCHEMA_URL,
        '@type': 'AggregateRating',
        reviewCount: consumerReviewsCount || 1,
        ratingValue: consumerRatingValue && consumerRatingValue.toFixed(1),
        bestRating: 5,
        worstRating: 1,
      }
    : undefined;
}

export function getReviewedItem({ makeModelSubmodelYear, consumerReviews }) {
  const make = get(makeModelSubmodelYear, 'make.name', '');
  const model = get(makeModelSubmodelYear, 'model.name', '');
  const year = get(makeModelSubmodelYear, 'year', '');

  return {
    itemReviewed: {
      '@type': 'Car',
      name: `${year} ${make} ${model}`,
      model,
      vehicleModelDate: year,
      brand: {
        '@type': 'Thing',
        name: make,
      },
      aggregateRating: getAggregateRatingFromCoreConsumerReviews(consumerReviews),
    },
  };
}

const getNotesList = items => ({
  '@type': 'ItemList',
  itemListElement: items.map((name, ind) => ({
    '@type': 'ListItem',
    position: ind + 1,
    name,
  })),
});

/**
 * Testing tool: https://search.google.com/structured-data/testing-tool
 * Schema: http://schema.org/Review
 *
 * For use with Editor's Review
 *
 * @param {Object} makeModelSubmodelYear Vehicle object
 * @param {Object} editorialReview Editor's Review data
 * @param {Object} consumerReviews Consumers' Reviews data
 * @param {Object} hasItemReviewed
 * @return {Object} seo review description
 */
export function getEditorialReviewSchema({
  makeModelSubmodelYear,
  editorialReview,
  author,
  consumerReviews,
  thumbUrl,
  hasItemReviewed = true,
  isCorePage = false,
}) {
  const editorReviewRating = get(editorialReview, 'ratings.overall.rating', '');
  const bestRating = editorReviewRating ? getRatingScale(editorialReview) : 5;
  const editorialReviewContent = editorialReview.body || editorialReview.summary;
  const organization = getOrganizationSchema();
  const headline = buildDisplayVehicle({
    makeModelSubmodelYear,
    params: {},
    trimDuplicateSubmodel: true,
    disableTrademarkCharacter: isCorePage,
  });
  const authorContent = isEmpty(author) ? null : parseContent(author);
  const cons = get(editorialReview, 'cons', []);
  const pros = get(editorialReview, 'pros', []);

  return {
    '@context': SCHEMA_URL,
    '@type': 'Review',
    reviewBody: editorialReviewContent,
    ...(isCorePage
      ? { datePublished: get(editorialReview, 'published'), dateModified: get(editorialReview, 'updated') }
      : {}),
    ...(isCorePage ? { description: 'Review, Pricing, and Specs' } : {}),
    ...(isCorePage ? { headline } : {}),
    ...(isCorePage ? { thumbnailURL: thumbUrl } : {}),
    publisher: organization,
    ...(pros.length ? { positiveNotes: getNotesList(pros) } : {}),
    ...(cons.length ? { negativeNotes: getNotesList(cons) } : {}),
    author: authorContent
      ? getPersonSchema({
          name: authorContent.metadata('authorName').value(),
          jobTitle: authorContent.metadata('authorTitle').value(),
          pathname: authorContent.metadata('authorLink').value(),
          photoUrl: getValidImageUrl(authorContent.metadata('photoPath').value(), SOURCES.STATIC),
        })
      : organization,
    ...(hasItemReviewed && getReviewedItem({ makeModelSubmodelYear, consumerReviews })),
    ...(editorReviewRating && {
      reviewRating: {
        '@type': 'Rating',
        ratingValue: parseFloat(editorReviewRating).toFixed(1),
        bestRating,
        worstRating: 1,
      },
    }),
  };
}

export function getConsumerReviewSchema({ makeModelSubmodelYear, consumerReviews, includeReviewedItem = true }) {
  const review = get(consumerReviews, 'reviews[0]');

  if (!review) return undefined;

  const { author, text, createTime, created, title } = review;

  return {
    '@context': SCHEMA_URL,
    '@type': 'Review',
    reviewBody: text,
    author: {
      '@context': SCHEMA_URL,
      '@type': 'Person',
      name: get(author, 'authorName', ''),
    },
    ...(includeReviewedItem && getReviewedItem({ makeModelSubmodelYear, consumerReviews })),
    datePublished: createTime || created,
    name: title,
  };
}

export function appendReviewSchema(
  carSchema,
  {
    makeModelSubmodelYear,
    editorialReview,
    author,
    consumerReviews,
    priceRange = [0],
    hasItemReviewed,
    thumbUrl,
    isCorePage = false,
  }
) {
  const editorialRatingValue = get(editorialReview, 'ratings.overall.rating');
  const minPrice = Math.min(...priceRange);
  const maxPrice = Math.max(...priceRange);

  return {
    ...carSchema,
    aggregateRating: editorialRatingValue ? undefined : carSchema?.aggregateRating,
    review: editorialRatingValue
      ? {
          ...getEditorialReviewSchema({
            makeModelSubmodelYear,
            editorialReview,
            author,
            consumerReviews,
            priceRange,
            hasItemReviewed,
            thumbUrl,
            isCorePage,
          }),
          ...((minPrice || maxPrice) &&
            !isCorePage && {
              potentialAction: {
                '@type': 'TradeAction',
                price: minPrice || maxPrice,
                priceCurrency: 'USD',
              },
            }),
        }
      : undefined,
  };
}
